import { render, staticRenderFns } from "./vehicle-add-or-update.vue?vue&type=template&id=22ba0c44"
import script from "./vehicle-add-or-update.vue?vue&type=script&lang=js"
export * from "./vehicle-add-or-update.vue?vue&type=script&lang=js"
import style0 from "./vehicle-add-or-update.vue?vue&type=style&index=0&id=22ba0c44&prod&lang=less"


/* normalize component */
import normalizer from "!../../../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports